<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="container fn text-white">
      <div>
        <div class="mb-1">
          <router-link :to="{ name: 'dashboard-hitler' }">
            <div class="all-title-head">
              ถอนเงิน
            </div>
            <div class="dtx2">
              <span>
                <i
                  class="fas fa-angle-left fa-2x"
                  style="color: #fff;"
                />
              </span>
            </div>
          </router-link>
        </div>
        <div
          v-if="agent.status_wit === '0'"
          class="text-center"
        >
          <hr>
          <h5 style="color: red">
            *ขออภัยขณะนี้เว็บไซต์ได้ทำการปิดระบบถอนเงิน
          </h5>
        </div>
        <div
          v-if="wto === null && agent.status_wit === '1'"
          class="row text-center"
        >
          <div
            class="col-6 mb-1"
          >
            <button
              v-if="User.acc_no"
              class="btnlogout"
              @click="selected('acc')"
            >
              บัญชีธนาคารไทย
            </button>
          </div>

          <div
            v-if="User.trueaccno"
            class="col-6 mb-1"
          >
            <button
              class="btnlogout"
              @click="selected('true')"
            >
              True Wallet
            </button>
          </div>

          <div
            v-if="User.acc_laos"
            class="col-6 mb-1"
          >
            <button
              class="btnlogout"
              @click="selected('laos')"
            >
              บัญชีธนาคารลาว
            </button>
          </div>
        </div>

        <div
          v-if="User.trueaccno && wto === 'true'"
          class="balance-cl"
        >
          <div class="d-flex justify-content-between">
            <div>
              <h6 style="color: #000">
                ทรูมันนี่วอลเล็ท
              </h6>
              <p style="font-size: 1.5rem; color: #ff7b00">
                {{ User.trueaccno }}
              </p>

              <h6 style="color: #000">
                ชื่อบัญชี
              </h6>
              <h6 style="color: #ff7b00">
                {{ User.name }} {{ User.surname }}
              </h6>
            </div>
            <div>
              <img
                src="/bankIcon/wallet.png"
                alt=""
                height="65"
              >
            </div>
          </div>
        </div>

        <div v-if="User.acc_no && wto === 'acc'">
          <div
            class="card7"
            :style="`background-color: ${User.bg};`"
          >
            <!-- <div
          class="card"
          :style="`background-color: ${AgentData.bg};`"
        > -->
            <div class="logo">
              <img
                :src="`/bankIcon/${User.path_photo}`"
                alt="logo-bank"
                height="75"
                style="float: right"
                class="mt-2"
              >
            </div>
            <div class="chip">
              <img
                src="/icon/chip.png"
                alt="chip"
              >
              <span class="bankk">{{ User.bankname }}</span>
            </div>
            <div class="number">
              {{ User.acc_no }}
            </div>
            <div class="name">
              {{ User.name }} {{ User.surname }}
            </div>
            <div class="from">
              <!-- THAILAND -->
            </div>
            <div class="to">
              <!-- 06/23 -->
            </div>
            <div class="ring" />
          </div>
        </div>
        <div v-if="User.acc_laos && wto === 'laos'">
          <div
            class="card7"
            :style="`background-color: ${User.bg};`"
          >
            <div class="logo">
              <img
                :src="`/bankIcon/${User.path_photo}`"
                alt="logo-bank"
                height="75"
                style="float: right"
                class="mt-2"
              >
            </div>
            <div class="chip">
              <img
                src="/icon/chip.png"
                alt="chip"
              >
              <span class="bankk">{{ User.bankname }}</span>
            </div>
            <div class="number">
              {{ User.acc_laos }}
            </div>
            <div class="name">
              {{ User.name }} {{ User.surname }}
            </div>
            <div class="from">
              <!-- THAILAND -->
            </div>
            <div class="to">
              <!-- 06/23 -->
            </div>
            <div class="ring" />
          </div>
        </div>
        <hr>
        <b-row class="mt-2 mb-2 p-0">
          <b-col
            md="12"
            class="mt-2 mb-1 text-center"
          >
            <small class="text-dark">ยอดเงินปัจจุบันที่จะถอน</small>
            <h3 style="color: #000">
              ฿{{
                Number(balance)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
            </h3>
          </b-col>
          <b-col cols="12">
            <div>
              <label
                for="balance"
                class="text-dark"
              >จำนวนครั้งการถอนของท่านวันนี้เหลือ {{ 5 - User.deposit_day_count }} ครั้ง</label>

              <b-form-input
                id="basicInput"
                v-model="amount"
                :disabled="User.deposit_day_count >= 5 || agent.status_wit === '0'"
                placeholder="จำนวนเงินที่ต้องการถอน"
                type="number"
                class="btx"
              />
            </div>
            <div class="demo-inline-spacing mt-2">
              <button
                :disabled="User.deposit_day_count >= 5 || agent.status_wit === '0'"
                block
                class="m-0 btnlogout"
                @click="submit"
              >
                ยืนยันการถอนเงิน
              </button>
            </div>
          </b-col>
        </b-row>

        <div class="text-center">
          <small class="text-danger text-weight-bolder">
            * งดการถอนเวลา 23.50- 00.30 และ 01.30 -02.30 ของทุกวัน
            เนื่องจากธนาคารปิดปรับปรุง
          </small>
        </div>
        <div class="text-center">
          <small class="text-danger text-weight-bolder">
            *ถอนเงินขั้นต่ำ 1 บาท สำหรับลูกค้าที่ใช้
          </small>
          <small style="color: #ff7b00"> บัญชีธนาคารไทย </small>
        </div>
        <div class="text-center">
          <small class="text-danger text-weight-bolder">
            *ถอนเงินขั้นต่ำ 100 บาท และ สูงสุด 500 บาท สำหรับลูกค้าที่ใช้
          </small>
          <small style="color: #ff7b00"> ทรูมันนี่วอลเล็ท </small>
        </div>
        <div class="text-center">
          <small class="text-danger text-weight-bolder">
            *ถอนเงินขั้นต่ำ 50 บาท สำหรับลูกค้าที่ใช้
          </small>
          <small style="color: #ff7b00"> บัญชีธนาคารลาว </small>
        </div>
        <hr>

        <div class="text-center">
          <h4 class="text-dark">
            สถานะการทำรายการ
          </h4>
        </div>

        <div
          v-for="(key, index) in withdrawData"
          :key="index._id"
          class="d-flex justify-content-between align-items-center p-1 mb-1 border rounded-lg"
        >
          <div>
            <small
              style="margin-top: 10px"
              class="text-dark"
            > {{ key.amount }} บาท </small>
          </div>

          <div>
            <small class="text-dark"> {{ key.timestamp }}</small><br>
          </div>

          <div>
            <b-badge
              v-if="key.status === 'processing'"
              variant="light-warning"
            >
              <small> กำลังดำเนินการ</small>
            </b-badge>
            <b-badge
              v-if="key.status === 'waiting' || key.status === 'error'"
              variant="light-warning"
            >
              <small> รอดำเนินการ</small>
            </b-badge>
            <b-badge
              v-if="key.status === 'success'"
              variant="light-success"
            ><small> สำเร็จ</small>
            </b-badge>
            <b-badge
              v-if="key.status === 'return'"
              variant="light-success"
            ><small> คืนเครดิต สำเร็จ</small>
            </b-badge>
          </div>
        </div>

        <h4 class="text-dark">
          กติกาการถอนเงิน
        </h4>
        <small class="text-dark">
          1. กรณีที่สมาชิกไม่รับโปรโมชั่นใดๆจะต้องมีรายการเล่นอย่างน้อย 1
          ครั้ง<br>
          2. กรณีที่มีการทุจริต การตัดสินใจของพนักงานถือเป็นที่สิ้นสุด<br>
          3. สมาชิกทุกท่านสามารถถอนเงินได้สูงสุด 100 เท่า นับจากยอดฝาก
          และเครดิตที่เกินจาก 100 เท่า ทั้งหมดทางเราขออนุญาติตัดออก<br>
          4. ลูกค้าที่ได้รับเครดิตฟรี จะไม่สามารถนำเครดิตไปซื้อ ‘ ฟรีสปิน ‘ได้
          ถ้าหากลูกค้าซื้อฟรีสปินทางเราของสงวนสิทธิ์การถอนทุกกรณี<br>
          *กรุณากด 'ปิดแอพพลิเคชันเกม'
          เพื่อตรวจสอบยอดเงินว่าตรงกับภายในเกมหรือไม่* คำเตือน<br>
          1. กรณีที่เกิดข้อผิดพลาดของระบบให้แจ้งกับพนักงานทันที<br>
          2.
          กรณีที่สมาชิกไม่แจ้งพนักงานทางเราขอสงวนสิทธิ์ทำรายการถอนเงินให้ทุกกรณี
        </small>
        <br><br><br><br>
        <br>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  // BInputGroup,
  BFormInput,
  BRow,
  BCol,
  BBadge,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    // BInputGroup,
    BFormInput,
    BRow,
    BCol,
    BBadge,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      show: true,
      balance: 0.0,
      amount: '',
      withdrawData: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      wto: null,
      agent: {},
    }
  },
  mounted() {
    this.GetAgentData()
    this.getbalance(this.userData.username)
    this.getwithdraw(this.userData.username)
    this.getUser(this.userData)
  },
  methods: {
    GetAgentData() {
      this.$http
        .get('https://api.ufabangkok.com/api/agent/show')
        .then(response => {
          // console.log(response.data.ListGames)
          this.agent = response.data
        })
        .catch(error => console.log(error))
    },
    selected(val) {
      this.wto = val
    },
    getUser(userData) {
      this.$http
        .get(`https://api.ufabangkok.com/api/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
    getbalance(username) {
      this.show = true
      const params = {
        username,
      }
      this.$http
        .get('https://api.ufabangkok.com/api/balance/getbalance', { params })
        .then(response => {
          this.show = false
          this.balance = response.data.data.balance
          if (this.balance < 0) {
            this.balance = 0
          }
        })
        .catch(error => console.log(error))
    },
    getwithdraw(username) {
      this.show = true
      this.$http
        .get(`https://api.ufabangkok.com/api/withdraw/username/${username}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.withdrawData = response.data
          let index = 0
          this.withdrawData.forEach(items => {
            this.withdrawData[index].timestamp = moment(items.created_at)
              .tz('Asia/Bangkok')
              .format('DD/MM/YYYY HH:mm:ss')
            index += 1
          })
        })
        .catch(error => console.log(error))
    },
    submit() {
      this.show = true
      // this.amount = this.balance.balance
      if (Number(this.amount) <= Number(this.balance)) {
        if (this.wto === null) {
          this.show = false
          this.SwalError('กรุณาเลือกบัญชีที่ต้องการให้เว็บโอนเข้า')
        } else if (Number(this.amount) < 1) {
          this.show = false
          this.SwalError('กรุณากรอกยอดเงินที่ต้องการถอนให้ถูกต้อง')
        } else if (this.wto === 'true' && (Number(this.amount) < 100 || Number(this.amount) > 500)) {
          this.show = false
          this.SwalError('กรุณากรอกยอดเงินที่ต้องการถอนให้ถูกต้อง')
        } else if (this.wto === 'laos' && Number(this.amount) < 50) {
          this.show = false
          this.SwalError('กรุณากรอกยอดเงินที่ต้องการถอนให้ถูกต้อง')
        } else {
          const formData = {
            username: this.userData.username,
            amount: this.amount,
            uid: this.userData.userID.id,
            wto: this.wto,
          }
          this.$http
            .post('https://api.ufabangkok.com/api/withdraw/storewithturn', formData)
            .then(response => {
              this.show = false
              this.amount = ''
              this.Success(response.data.message)
              this.getbalance(this.userData.username)
              this.getwithdraw(this.userData.username)
            })
            .catch(error => {
              this.show = false
              this.SwalError(error.response.data.message)
            })
        }
      } else {
        this.show = false
        this.SwalError('ยอดเครดิต ไม่เพียงพอสำหรับการถอน')
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color:#000">ทำรายการสำเร็จ<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
.btx {
  border-radius: 16px !important;
  border: 2px solid #acacac48 !important;
  color: #050505;
}
.container {
  max-width: 540px;
}
.btn {
  border-radius: 6px;
  width: 100vw;
  border: 0;
  background: linear-gradient(0deg, rgb(255, 0, 0), #5d039b);
  border: 2px solid #fff;
  color: #fff;
}
.btn:hover {
  background: linear-gradient(22deg, rgb(255, 0, 0) 0%, #5d039b 100%);
}
p.highlight {
  line-height: 1.9;
  font-size: 2.6rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
a {
  color: #fff;
}
i {
  color: rgb(255, 255, 255);
}
small {
  font-size: 0.8rem;
}
.dtx {
  position: relative;
  display: flex;
  margin-top: -40px;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  align-items: center;
}
.dtx2 {
  position: relative;
  display: flex;
  margin-left: 5px;
  margin-top: -38px;
  justify-content: left;
  color: #000;
  font-weight: 600;
  align-items: left;
}

.cs {
  background: linear-gradient(to bottom, #7222416e, #58585870);
  border-radius: 10px;
  /* color: #fff; */
  padding: 0.5rem;
  border: rgba(255, 255, 255, 0.527) solid 3px;
  /* box-shadow: #8826ff94 0px 5px 10px 5px; */
}
.circle {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(#006db3, #29b6f6);
}
.circles {
  position: absolute;
  height: 270px;
  width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle-1 {
  height: 180px;
  width: 180px;
  top: -50px;
  left: -60px;
}
.circle-2 {
  height: 200px;
  width: 200px;
  bottom: -90px;
  right: -90px;
  opacity: 0.8;
}
/* Background circles end */

/* .card-group {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.card7 {
  margin: auto;
  position: relative;
  height: 210px;
  max-width: 380px;
  border-radius: 15px;
  /* background: rgb(163, 0, 0); */
  /* background-color: #4e2e7f; */
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.logo img,
.chip img,
.number,
.bankk,
.name,
.from,
.to,
.ring {
  position: absolute; /* All items inside card should have absolute position */
}

.logo img {
  top: 15px;
  right: 10px;
  width: 80px;
  height: auto;
  opacity: 0.8;
}

.chip img {
  top: 60px;
  left: 20px;
  width: 50px;
  height: auto;
  opacity: 0.8;
}
.bankk {
  top: 20px;
  left: 20px;
  font-size: 1.3rem;
  color: #fff;
}

.number,
.name,
.from,
.to {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  letter-spacing: 2px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.number {
  left: 20px;
  font-size: 1.5rem;
  bottom: 65px;
  font-family: 'Nunito', sans-serif;
}

.name {
  font-size: 1rem;
  left: 20px;
  bottom: 35px;
}

.from {
  font-size: 1rem;
  bottom: 35px;
  right: 90px;
}

.to {
  font-size: 1rem;
  bottom: 35px;
  right: 20px;
}

/* The two rings on the card background */
.ring {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: transparent;
  border: 50px solid rgba(255, 255, 255, 0.1);
  bottom: -250px;
  right: -250px;
  box-sizing: border-box;
}

.ring::after {
  content: '';
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: transparent;
  border: 30px solid rgba(255, 255, 255, 0.1);
  bottom: -80px;
  right: -110px;
  box-sizing: border-box;
}

/* asdasd */
</style>

<style lang="scss" scoped>
.form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 6px;
}
</style>
